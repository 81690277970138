import React, { useMemo, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { Icon } from "components/Icon";
import classNames from "classnames";

const IconButton = ({
  showLabel = true,
  iconName = "Puzzle",
  variant = "secondary",
  children,
  ...other
}) => {
  const buttonStyle = {};

  if (!showLabel) {
    // buttonStyle.padding = "0 0.25rem";
  }

  return (
    <Button
      {...other}
      variant={variant}
      style={buttonStyle}
      className={classNames(showLabel ? null : "px-2", "py-0")}
      // TODO: toggle modal window
    >
      <Icon
        name={iconName}
        title={""}
        className={classNames(showLabel && "me-1")}
        size={0.75}
        alignText
      />
      {showLabel && children}
    </Button>
  );
};

const ViewSearch = ({ className, ...other }) => {
  return (
    <Form {...other} className={classNames("col-sm-3", "col-xl-2", className)}>
      <InputGroup>
        <Form.Control size="sm" type="search" placeholder="Search ${view}" />
        <IconButton
          title="Search"
          iconName="Magnify"
          variant="secondary"
          showLabel={false}
          size="sm"
        />
      </InputGroup>
    </Form>
  );
};

const renderCheckRow = ({
  title,
  as,
  checked,
  setChecked,
  id,
  className,
  ...other
}) => {
  const RenderComponent = as ? as : Form.Check;

  return (
    <Form.Group key={id} controlId={id} className={classNames(className)}>
      <RenderComponent
        {...other}
        label={title}
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
      />
    </Form.Group>
  );
};

const ViewPreferences = ({ title, className, ...other }) => {
  // TODO: Extract field visibility to individual preference component with its own state
  const [showId, setShowId] = useState(true);
  const [showOwner, setShowOwner] = useState(true);
  const [showCreatedDate, setShowCreatedDate] = useState(false);
  const [showModifiedDate, setShowModifiedDate] = useState(true);

  // TODO: Extract options, extract
  const displayFields = [
    {
      title: "Title (required)",
      checked: true,
      setChecked: () => {},
      id: "title",
      disabled: true,
    },
    {
      title: "ID",
      checked: showId,
      setChecked: setShowId,
      id: "id",
    },
    {
      title: "Owner",
      checked: showOwner,
      setChecked: setShowOwner,
      id: "owner",
    },
    {
      title: "Created Date",
      checked: showCreatedDate,
      setChecked: setShowCreatedDate,
      id: "created-date",
    },
    {
      title: "Modified Date",
      checked: showModifiedDate,
      setChecked: setShowModifiedDate,
      id: "modified-date",
    },
  ];

  return (
    <Dropdown className={classNames(className)}>
      <Dropdown.Toggle id="a-b-c" size="sm" variant="secondary">
        <Icon title={title} name="TuneVariant" size={0.75} alignText={true} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header className={classNames("h5")}>
          Show Fields
        </Dropdown.Header>
        <div className={classNames("px-3", "py-2")}>
          {displayFields.map((option) => renderCheckRow(option))}
        </div>
        <Dropdown.Header className={classNames("h5")}>
          Page Size
        </Dropdown.Header>
        <div className={classNames("px-3", "py-2")}>
          <Form.Select defaultValue={10}>
            <option value={10}>10 items</option>
            <option value={25}>25 items</option>
            <option value={50}>50 items</option>
            <option value={100}>100 items</option>
          </Form.Select>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ViewFilterToggle = ({
  labelText = "Show/Hide Filters",
  isOpen = false,
  setIsOpen = () => {},
}) => {
  return (
    <IconButton
      type="button"
      size="sm"
      iconName="Filter"
      showLabel={false}
      role="switch"
      variant={isOpen ? "secondary" : "outline-secondary"}
      aria-label={labelText}
      className={classNames(isOpen && "active")}
      onClick={() => setIsOpen(!isOpen)}
    />
  );
};

export { IconButton, ViewSearch, ViewPreferences, ViewFilterToggle };
