import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { usePreference } from "utils/hooks/usePreference";
import { ViewToolbar } from "components/toolbars";
import { ReactMockForm } from "mocks/edit-view/MockForm";
import { MockAppView } from "mocks/MockAppView";
import {
  IconButton,
  ViewSearch,
  ViewPreferences,
  ViewFilterToggle,
} from "mocks/Toolbar";

const query = graphql`
  query MockStageViewQuery {
    mockStageMenu: allMockStageMenuYaml {
      items: edges {
        menuItem: node {
          id
          title
          to
        }
      }
    }
  }
`;

// TODO: Refactor to use ApplicationContext
const MockStage = () => {
  // MOCKED data; this represents information that would come from
  // higher-order/external definitions to a completed view.
  const [showFilters, setShowFilters] = usePreference(
    "mockStage:showFilters",
    false
  );
  const mockTitle = "Table View";
  const mockInstructions = `Table and list views may include instructions to explain
    presented data, or a task the user is expected to perform.`;
  const mockMessages = [
    // {
    //   variant: "danger",
    //   content: "Danger message content here",
    // },
  ];
  const mockCloseTo = "/"; // change between '/' vs. null to show/hide close button
  const mockAppToolbarItems = (
    <>
      <Button variant="primary">Save</Button>
    </>
  );
  const mockViewToolbarItems = (
    <>
      <IconButton variant="primary" size="sm" iconName="PlusCircle">
        Add Record
      </IconButton>
      <ViewSearch className={classNames("ms-auto")} />
      <ViewPreferences />
      <ViewFilterToggle isOpen={showFilters} setIsOpen={setShowFilters} />
    </>
  );

  const data = useStaticQuery(query);
  const menuItems = useMemo(
    () => data.mockStageMenu.items.map((item) => item.menuItem),
    [data.mockStageMenu]
  );

  return (
    <MockAppView
      title={mockTitle}
      instructions={mockInstructions}
      messages={mockMessages}
      tabs={menuItems}
      closeTo={mockCloseTo}
      toolbarItems={mockAppToolbarItems}
    >
      <ViewToolbar>{mockViewToolbarItems}</ViewToolbar>
      <ReactMockForm />
    </MockAppView>
  );
};

export default MockStage;
export { query };
